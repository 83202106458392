<template>
  <div class="home page">
    <!--  -->
    <div class="board">
      <!-- 一.顶部统计数据、车位利用率 -->
      <div class="board_top fx bottom24">
        <!-- 1.顶部统计数据 -->
        <div class="top_data right24 " style="width: 66%!important;">
          <div class=" flx bottom24">
            <div class="top_data_block top_data_block1 fx right24">
              <img class="top_img" src="@/assets/images/newhome/home_1.png" alt="">
              <div class="top_main">
                <p class="top_main_title">今日累计收益</p>
                <p class="top_main_num">{{ allTotal.todayEarnTotal }}</p>
                <p class="top_main_bottom">昨日累计收益：{{ allTotal.yesterdayEarnTotal || 0 }}</p>
              </div>
            </div>
            <div class="top_data_block top_data_block2 fx right24">
              <img class="top_img" src="@/assets/images/newhome/home_2.png" alt="">
              <div class="top_main">
                <p class="top_main_title">本月累计收益</p>
                <p class="top_main_num">{{ allTotal.monthEarnTotal }}</p>
                <p class="top_main_bottom">上月累计收益：{{ allTotal.lastMonthEarnTotal || 0 }}</p>
              </div>
            </div>
            <div class="top_data_block top_data_block3 fx">
              <img class="top_img" src="@/assets/images/newhome/home_3.png" alt="">
              <div class="top_main">
                <p class="top_main_title">历史累计欠费</p>
                <p class="top_main_num">{{ allTotal.arrearageEarnTotal }}</p>
              </div>
            </div>
          </div>

          <div class=" flx">
            <div class="top_data_block top_data_block4 fx right24">
              <img class="top_img" src="@/assets/images/newhome/home_4.png" alt="">
              <div class="top_main">
                <p class="top_main_title">单泊位平均收益</p>
                <p class="top_main_num">{{ allTotal.spaceEarn }}</p>
              </div>
            </div>
            <div class="top_data_block top_data_block5 fx right24">
              <img class="top_img" src="@/assets/images/newhome/home_5.png" alt="">
              <div class="top_main">
                <p class="top_main_title">车位平均收缴率</p>
                <p class="top_main_num">{{ allTotal.payRate }}</p>
              </div>
            </div>
            <div class="top_data_block top_data_block6 fx">
              <img class="top_img" src="@/assets/images/newhome/home_6.png" alt="">
              <div class="top_main">
                <p class="top_main_title">今日累计订单数</p>
                <p class="top_main_num">{{ allTotal.todayOrderCount || 0 }}</p>
                <p class="top_main_bottom">昨日累计订单数：{{ allTotal.yesterdayOrderCount || 0 }}</p>
              </div>
            </div>
          </div>

        </div>
        <!-- 2.车位利用率 -->
        <div class="top_use board_box">
          <div class="headTitle">
            今日车位利用率
          </div>
          <div class="chart-content">
            <BaseChart ref="zrcwlylChart" :option="zrcwlylChartOption" style="width: 100%; height: 100%" />
          </div>
        </div>
      </div>
      <!-- 二.收入趋势、泊位占用 -->
      <div class="board_second fx bottom24">
        <!-- 1.收入趋势 -->
        <div class="board_income board_box" style="width: 66%!important;">
          <div class="headTitle flx" style="justify-content: space-between;align-items: center;">
            <!--                <img src="@/assets/images/home/group.png" />-->
            <div>
              <span v-if="vehicleTimeSelect == 0">今日</span>
              <span v-else-if="vehicleTimeSelect == 1">本周</span>
              <span v-else-if="vehicleTimeSelect == 2">本月</span>
              <span style="margin-left: 0;">收入趋势</span>
            </div>
            <div style="display: flex;">
              <!-- <div style="margin: 0 0 0 auto">
                    <el-radio-group v-model="vehicleTypeSelect" size="small" @change="searchParkingFeeChangeAnalyze">
                      <el-radio-button label="">全部</el-radio-button>
                      <el-radio-button label="1">路内</el-radio-button>
                      <el-radio-button label="0">路外</el-radio-button>
                    </el-radio-group>
                  </div> -->
              <div class="margin-left20">
                <el-radio-group v-model="vehicleTimeSelect" size="small" @change="searchParkingFeeChangeAnalyze">
                  <el-radio-button label="0">今日</el-radio-button>
                  <el-radio-button label="1">本周</el-radio-button>
                  <el-radio-button label="2">本月</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="chart-content-income">
            <BaseChart ref="topChart" :option="topChartOption" style="width: 100%; height: 100%" />
          </div>
        </div>
        <!-- 2.泊位占用 -->
        <div class="board_occupied board_box">
          <div class="headTitle">
            泊位占用分析
          </div>
          <div class="board_occupied_btm flx">
            <div class="board_occupied_main fx">
              <div class="board_occupied_box">
                <p class="board_occupied_title">泊位总数</p>
                <p class="board_occupied_num">{{ occupiedData.spaceTotalCount || 0 }}</p>
              </div>
              <div class="board_occupied_box">
                <p class="board_occupied_title">泊位利用率</p>
                <p class="board_occupied_num">{{ occupiedData.occupancy || 0 }}%</p>
              </div>
              <div class="board_occupied_box">
                <p class="board_occupied_title">占用泊位</p>
                <p class="board_occupied_num" style="color: #08BF9E;">{{ occupiedData.spaceUseCount || 0 }}</p>
              </div>
              <div class="board_occupied_box">
                <p class="board_occupied_title">空闲泊位</p>
                <p class="board_occupied_num">{{ occupiedData.spaceEmptyCount || 0 }}</p>
              </div>
              <div class="board_occupied_box">
                <p class="board_occupied_title">泊位周转率</p>
                <p class="board_occupied_num">{{ occupiedDataMore.parkingRate || 0 }}</p>
              </div>
              <div class="board_occupied_box">
                <p class="board_occupied_title">平均停放时长</p>
                <p class="board_occupied_num">{{ occupiedDataMore.parkingArrangeTime || 0 }}h</p>
              </div>
            </div>
            <div class="chart-content-occupied">
              <BaseChart ref="occupiedChart" :option="occupiedChartOption" style="width: 100%; height: 100%" />
              <img src="@/assets/images/newhome/occupied.png" alt="">
            </div>
          </div>

        </div>
      </div>
      <!-- 三.停车时长、进出场流量、本月车场收益排名 -->
      <div class="board_middle fx bottom24">
        <!-- 1.停车时长 -->
        <div class="board_time board_box">
          <div class="headTitle">
            停车时长分析
          </div>
          <div class="chart-content-time">
            <BaseChart ref="timeChart" :option="timeChartOption" style="width: 100%; height: 100%" />
          </div>
        </div>
        <!-- 2.进出场流量 -->
        <div class="board_flow board_box">
          <div class="headTitle flx" style="justify-content: space-between;align-items: center;">
            <div>
              
              <span style="margin-left: 0;">进出场流量分析</span>
            </div>
            <div style="display: flex;">
              <div style="margin: 0 0 0 auto">
                    <el-radio-group v-model="flowTypeSelect" size="small" @change="getFlowRevenueSort">
                      <!-- <el-radio-button label="">全部</el-radio-button> -->
                      <el-radio-button label="1">进场</el-radio-button>
                      <el-radio-button label="0">出场</el-radio-button>
                    </el-radio-group>
                  </div>
              <div class="margin-left20">
                <el-radio-group v-model="flowTimeSelect" size="small" @change="getFlowRevenueSort">
                  <el-radio-button label="0">今日</el-radio-button>
                  <el-radio-button label="1">本周</el-radio-button>
                  <el-radio-button label="2">本月</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="chart-content-flow">
            <BaseChart ref="flowChart" :option="flowChartOption" style="width: 100%; height: 100%" />
          </div>

        </div>
        <!-- 3.本月车场 -->
        <div class="board_space board_box">
          <div class="headTitle">
            本月路外车场收益top5
          </div>
          <div class="chart-content_space ">
            <el-table :data="parkingLotRevenueSortDataList" border class="common-table"
              header-cell-style="background-color: rgba(0, 0, 0, 0.02);color: rgba(0, 0, 0, 0.88);font-weight:600;"
              size="medium" style="width: 100%">
              <el-table-column align="left" fixed="left" label="车场名称" prop="ccmc" min-width="80%">
                <template slot-scope="scope">
                  <div style="display: flex;">
                    <img v-if="scope.$index < 3" src="@/assets/images/newhome/top_1.png" class="width20 height20" />
                    <img v-else src="@/assets/images/newhome/top_2.png" class="width20 height20" />
                    <div class="font-size14 margin-left8">
                      {{ scope.row.parkingLotName }}
                    </div>
                    <!-- <div v-if="scope.row.parkingLotType === 1"
                      class="width40 height22 font-size12 margin-left8 border-radius4" style="background: #FFF6D9;border: 1px #FFBB01 solid;
                             color: #FFBB01;display: flex; align-items: center;justify-content: center;">
                      路内
                    </div>
                    <div v-else-if="scope.row.parkingLotType === 0"
                      class="width40 height22 font-size12 margin-left8 border-radius4" style="background: #ECF3FF;border: 1px #0768FD solid;
                             color: #0768FD;display: flex; align-items: center;justify-content: center;">
                      路外
                    </div> -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="left" fixed="left" label="收入（元）" prop="sr" min-width="20%">
                <template slot-scope="scope">
                  <span v-if="scope.row.transactionAmount === 0">0.00</span>
                  <span v-else-if="scope.row.transactionAmount !== ''">
                    {{ scope.row.transactionAmount &&
                      parseFloat(scope.row.transactionAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseChart from "@/components/BaseChart";
// import * as echarts from "echarts";
import {
  searchParkingFeeChangeAnalyze, // 收入趋势
  searchParkingSpaceAnalyze, // 泊位利用率

  // deviceAnalysis,
} from "@/api/home";
import {
  getParkingOverview, // 总览数据
  searchParkingTimeAnylizeNew, // 停车时长
  searchParkingSpaceTotal, // 泊位占用分析数量
  searchParkingLotAnalyze, // 泊位占用时长
  searchParkingOrderAnalyze, // 进出场流量分析

  parkingLotRevenueSort, // 收入排名

} from "@/api/dataBoard";
import {
  checkToken,
} from "@/api/common";
import { MessageBox } from "element-ui";

export default {
  name: "home",
  components: {
    BaseChart,
  },
  data() {
    return {
      parkinglotTimeSelect: "0", //车场概况时间选择

      earningList: {
        "arrearsTotal": "0.00",
        "monthTotal": "0.00",
        "spaceAreage": "0.00",
        "spacePayRate": "0.00%",
        "yesterdayTotal": "0.00",
        "arrearageEarnTotal": "0.00",
      },
      allTotal: {},
      parkingLotRevenueSortSelect: 0,
      parkingLotRevenueSortDataList: [], // 收入排名
      screenHeight: document.body.clientHeight - 110,
      screenWidth: document.body.clientWidth,
      // 车位利用率
      zrcwlylChartOption: {
        color: "#0768FD",
        grid: {  //方法 1
          left: 10,
          right: 5,
          bottom: 0,
          top: 30,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          boundaryGap: false,
          axisLabel: {
            interval: 3,
            color: '#6C757D'
          },
          axisTick: {
            length: 6,
            lineStyle: {
              type: 'dashed',
              color: 'rgba(205, 205, 205, 1)',
            },
          },
          splitLine: {
            length: 4,
            lineStyle: {
              type: "solid",
              color: 'rgba(234, 234, 234, 1)',
            },
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisTick: {
            length: 6,
            lineStyle: {
              type: 'dashed',
              color: 'rgba(205, 205, 205, 1)',
            },
            color: '#6C757D'
          },
        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true,
            symbol: 'none',
            borderWidth: "4",
            lineStyle: {
              width: 4,
            },
            areaStyle: {
              color: 'rgba(199, 228, 255, 0.7)'
            },
          }
        ]
      },
      flowTimeSelect:0,
      flowTypeSelect: '1',

      vehicleTimeSelect: 0,
      vehicleTypeSelect: '1',
      topChartAverageRevenue: 0,
      // 收入趋势
      topChartOption: {
        height: 300,
        grid: {
          // width: 1200, //设置宽度为800px
          containLabel: true,
          left: 20,
          right: 20,
          bottom: 20,
          top: 30,
        },
        color: ['#0768FD', '#FDB600'],
        legend: {
          icon: 'circle',
          data: ['路外车场', '路内车场'],
          orient: 'horizontal',
          // bottom: 40,
          left: 'right',
          // y:'bottom',
          top: '0%',
          show: false,
          // y:10,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            // var result = ''
            var l_title = `<div class="font-size12" style="color:rgba(108, 117, 125, 1);">${params[0].axisValue}</div>`
            var hr = `<div class="margin-bottom8" style="height: 1px;background: rgba(242, 244, 248, 1);"></div>`
            var dotHtml = '<span class="margin-right5 border-radius12 width12 height12" style="display:inline-block;background-color:#0768FD"></span>'    // 定义第一个数据前的圆点颜色
            // var dotHtml2 = '<span class="margin-right5 border-radius12 width12 height12" style="display:inline-block;background-color:#0768FD"></span>'    // 定义第二个数据前的圆点颜色
            // result += l_title + "</br>" + dotHtml + ` ${params[0].seriesName} ` + params[0].data + "</br>" + dotHtml2 + ` ${params[1].seriesName} ` + params[1].data;
            var result2 = `${l_title}${hr}${dotHtml} <b>${params[0].seriesName}\t\t\t\t${params[0].data}</b></br></b>`
            return result2
            // return params[0].seriesName+' '+params[0].value
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          // axisLine:{
          //   symbol: 'arrow',
          //   lineStyle: {
          //     type: 'dashed'
          //   }
          // },
          axisTick: {
            length: 6,
            lineStyle: {
              type: 'dashed',
              color: 'rgba(205, 205, 205, 1)',
            },
          },
          data: [],
          // axisTick: {
          //   show: true
          // },
          splitLine: {
            lineStyle: {
              type: "solid",
              color: 'rgba(234, 234, 234, 1)',
            },
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            length: 6,
            lineStyle: {
              type: 'dashed',
              color: 'rgba(205, 205, 205, 1)',
            },
          },
        },
        series: [
          {
            data: [],
            type: "line",
            name: '路外收益',
            // smooth: true,
            // type: "bar",
            // barWidth: "20%",
            itemStyle: {
              color: "#0768FD",
            },
            lineStyle: {
              width: 4,
            },
          },
          {
            data: [],
            name: '路内车场',
            type: "line",
            // smooth: true,
            // type: "bar",
            // barWidth: "20%",
            itemStyle: {
              color: "#0768FD",
            },
            lineStyle: {
              width: 5,
            },
          },
        ],
      },
      occupiedData: {},
      occupiedDataMore: {},
      // 泊位占用分析
      occupiedChartOption: {
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          show: false,
          orient: 'vertical',
          x: 'right',
          data: ['90%', '10%'],  //显示的百分比
          textStyle: {
            color: "#fff",
            fontSize: "12"
          }

        },
        graphic: {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: '',     //环形中间文字及样式
            textAlign: 'center',
            fill: '#000000',
            width: 30,
            height: 30,
            fontSize: "12"
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],//第一个参数是内圆半径，第二个参数是外圆半径
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  textStyle: { color: 'white', fontSize: "0" },
                  //让series 中的文字进行换行
                  // formatter:function(val){
                  //     return val.name.split("-").join("\n");}
                },
                title: {
                  text: 'aaaa'
                },
                labelLine: {
                  show: true,
                  lineStyle: { color: 'white' }
                }
              },
              
              // emphasis: {
              //   // show:false,
              //   shadowBlur: 10,
              //   shadowOffsetX: 0,
              //   shadowColor: 'rgba(0, 0, 0, 0.5)',
              //   textColor: 'red'
              // }
            },
            emphasis: {
                disabled: true
              },
            data: [
              { value: 0, name: '空闲泊位' },
              { value: 0, name: '占用泊位' },
            ]
          }
        ],
        color: ['#EAF2F7','#08BF9E' ]
      },
      // 停车时长分析
      timeChartOption: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          // left: '3%',
          // right: '4%',
          // bottom: '3%',
          containLabel: true,
          left: 10,
          right: 10,
          bottom: 10,
          top: 20,
        },
        xAxis: [
          {
            type: 'category',
            data: ['30分钟以下', '30-60分钟', '1-3小时', '3小时以上', '1天以上'],


            axisLabel: {
              interval: 0,
              color: '#6C757D'
            },
            axisTick: {
              length: 6,
              lineStyle: {
                type: 'dashed',
                color: 'rgba(205, 205, 205, 1)',
              },
            },
            splitLine: {
              length: 4,
              lineStyle: {
                type: "solid",
                color: 'rgba(234, 234, 234, 1)',
              },
              show: false,
            },
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            barWidth: '50%',
            data: [10, 52, 200, 330, 220],
            color: "#0768FD"
          }
        ]
      },
      // 进出场流量分析
      flowChartOption: {
        color: "#641DFF",
        grid: {  //方法 1
          left: 10,
          right: 5,
          bottom: 0,
          top: 20,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          boundaryGap: false,
          axisLabel: {
            interval: 3,
            color: '#6C757D'
          },
          axisTick: {
            length: 6,
            lineStyle: {
              type: 'dashed',
              color: 'rgba(205, 205, 205, 1)',
            },
          },
          splitLine: {
            length: 4,
            lineStyle: {
              type: "solid",
              color: 'rgba(234, 234, 234, 1)',
            },
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisTick: {
            length: 6,
            lineStyle: {
              type: 'dashed',
              color: 'rgba(205, 205, 205, 1)',
            },
            color: '#6C757D'
          },
        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true,
            symbol: 'none',
            borderWidth: "4",
            lineStyle: {
              width: 4,
            },
            areaStyle: {
              color: 'rgba(199, 228, 255, 0.7)'
            },
          }
        ]
      },
      chart: null,
      colorItem: {
        "高点": '#FF531D',
        "视频桩": '#FF891D',
        "巡检车": '#FFB21D',
        "PDA": '#FFD27C',
        "地磁": '#FFF7CB',
      },
    }
  },
  mounted() {
    // this.isLoading = true;
    const that = this
    that.setChartSize()

    window.onresize = () => {
      return (() => {
        // 可以限制最小高度
        // if (document.body.clientHeight - 240 < 450) {
        //   return
        // }

        window.screenHeight = document.body.clientHeight - 110
        window.screenWidth = document.body.clientWidth
        that.screenHeight = window.screenHeight < 900 ? 900 : window.screenHeight
        that.screenWidth = window.screenWidth

        that.setChartSize()

      })()
    }
    checkToken()
      .then(res => {
        if (!res || res.code !== 30) {
          MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
            confirmButtonText: '重新登录',
            callback: () => {
              window.localStorage.clear()
              window.$vue.$router.push('/login')
            }
          });
        } else {
          Promise.all([
            // this.getEarningList(),
            // this.getVehicleAnalisys(),
            // this.getComprehensiveAnalysis(),
            // this.getParkingLotTotal(),
            // this.getParkingSpaceTotal(),
            this.getParkingOverview(),
            this.getParkingLotRevenueSort(0),
            this.searchParkingFeeChangeAnalyze(),
            this.searchParkingSpaceTotal(),
            // this.searchParkingLotAnalyze(),
            // this.searchParkingOrderAnalyze(),
            this.searchParkingSpaceAnalyze(),
            // this.deviceAnalysis(),
            this.getTimeRevenueSort(),
            this.getFlowRevenueSort(),
          ]).then(() => {
            // this.isLoading = false;
          });
        }
      })
  },
  methods: {
    setChartSize() {

      this.zrcwlylChartOption.height = 170 / 1920 * this.screenWidth;
      this.$refs.zrcwlylChart.render(this.zrcwlylChartOption);
      this.$refs.zrcwlylChart.onResize();

      this.topChartOption.height = 175 / 1920 * this.screenWidth;
      this.$refs.topChart.render(this.topChartOption);
      this.$refs.topChart.onResize();

      this.timeChartOption.height = 250 / 1920 * this.screenWidth;
      this.$refs.timeChart.render(this.timeChartOption);
      this.$refs.timeChart.onResize();

      this.occupiedChartOption.height = 260 / 1920 * this.screenWidth;
      this.$refs.occupiedChart.render(this.occupiedChartOption);
      this.$refs.occupiedChart.onResize();

      this.flowChartOption.height = 240 / 1920 * this.screenWidth;
      this.$refs.flowChart.render(this.flowChartOption);
      this.$refs.flowChart.onResize();
    },
    // 数据总览
    async getParkingOverview() {

      const { returnObject } = await getParkingOverview({
        type: 0,
      });

      this.allTotal = returnObject;
      // this.monthEarnTotal = returnObject.monthEarnTotal;
      // this.arrearageEarnTotal = returnObject.arrearageEarnTotal;
      // this.payRate = returnObject.payRate;
      // this.spaceEarn = returnObject.spaceEarn;
      // this.todayEarnTotal = returnObject.todayEarnTotal;
      // this.serviceTrainNum = returnObject.serviceTrainNum;
    },
    // 今日车位利用率
    async searchParkingSpaceAnalyze() {
      searchParkingSpaceAnalyze({ parkingLotType: 0 })
        .then(res => {
          if (res && res.code === 30 && res.result) {
            this.zrcwlylChartOption.xAxis.data = []
            this.zrcwlylChartOption.series[0].data = []
            for (var key in res.returnObject.parkingSpaceAnalyze[0]) {
              this.zrcwlylChartOption.xAxis.data.push(key)
              this.zrcwlylChartOption.series[0].data.push(res.returnObject.parkingSpaceAnalyze[0][key])
            }
            this.$refs.zrcwlylChart.render(this.zrcwlylChartOption);
            this.setChartSize()
          }
        })
    },
    // 收入趋势
    async searchParkingFeeChangeAnalyze() {
      const res = await searchParkingFeeChangeAnalyze({ parkingLotType: 0, type: this.vehicleTimeSelect });
      if (res && res.code === 30 && res.result) {
        this.topChartAverageRevenue = res.returnObject.averageRevenue
        this.topChartOption.xAxis.data = []
        this.topChartOption.series[0].data = []
        this.topChartOption.series[1].data = []
        for (var key in res.returnObject.parkingLot[0]) {
          this.topChartOption.xAxis.data.push(key)
          this.topChartOption.series[0].data.push(0)
          this.topChartOption.series[1].data.push(res.returnObject.parkingLot[0][key])
        }
        this.$refs.topChart.render(this.topChartOption);
      }
    },
    // 泊位占用分析
    async searchParkingSpaceTotal() {

      const res = await searchParkingSpaceTotal({ parkingLotType: 0 });
      const result = await searchParkingLotAnalyze({ parkingLotType: 0 });
      this.occupiedDataMore = result.returnObject;
      if (res && res.code === 30 && res.result) {
        this.occupiedData = res.returnObject;
        this.occupiedData.occupancy = (res.returnObject.spaceUseCount*100 / res.returnObject.spaceTotalCount).toFixed(2) ;
        
        this.occupiedChartOption.series[0].data[0].value = res.returnObject.spaceEmptyCount // 泊位空闲
        this.occupiedChartOption.series[0].data[1].value = res.returnObject.spaceUseCount // 泊位占用
        this.$refs.occupiedChart.render(this.occupiedChartOption)
      }


    },

    // 获取车场收入TOP10数据
    // type 类型 0-按昨日排名 1-按月排名
    async getParkingLotRevenueSort() {
      const res = await parkingLotRevenueSort({ type: 0 });
      if (res && res.code === 30 && res.result) {
        this.parkingLotRevenueSortDataList = res.returnObject.parkingLotSortResultVoList.slice(0, 5);
        for (let i = this.parkingLotRevenueSortDataList.length; i < 5; i++) {
          this.parkingLotRevenueSortDataList.push(
            {
              "parkingLotName": "",
              "parkingLotRevenue": "",
              "parkingLotType": -1
            }
          )
        }
      }
    },
    // 停车时长分析
    async getTimeRevenueSort() {
      const res = await searchParkingTimeAnylizeNew({ parkingLotType: 0 })
      console.log(res, "time");
      if (res && res.code === 30 && res.result) {
          this.timeChartOption.xAxis[0].data = []
          this.timeChartOption.series[0].data = []
          
          res.returnObject.forEach(item=>{
            this.timeChartOption.xAxis[0].data.push(item.name)
            this.timeChartOption.series[0].data.push(item.count)
          })
          this.$refs.timeChart.render(this.timeChartOption);
      }

    },
    // 进出场流量分析
    async getFlowRevenueSort() {
      const res = await searchParkingOrderAnalyze({parkingLotType: 0, type: this.flowTimeSelect, orderType: this.flowTypeSelect});
      if (res && res.code === 30 && res.result) {
        console.log(res.returnObject)
        this.flowChartOption.xAxis.data = []
        this.flowChartOption.series[0].data = []
        for (var key in res.returnObject.orderAnalyze[0]) {
          this.flowChartOption.xAxis.data.push(key)
          this.flowChartOption.series[0].data.push(res.returnObject.orderAnalyze[0][key])
        }
        this.$refs.flowChart.render(this.flowChartOption);

      }

    },
  }
}
</script>

<style lang="less" scoped>
.home {
  min-width: 1600px;

  .right24 {
    margin-right: 24px;
  }

  .bottom24 {
    margin-bottom: 24px;
  }

  .fx {
    display: flex;
    align-items: center;
  }

  .flx {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .headTitle {
    font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB;
    font-weight: 600;
    font-size: 18px;
    color: #343A40;
    line-height: 20px;
    // margin-bottom: 20px;
    margin: 15px 25px 0px;
  }

  .board_box {
    background-color: white;
    border-radius: 12px;
  }

  .board {
    width: 100%;
    height: 100%;

    .board_top {
      height: 264px;

      // .top_data_block:nth-child(1) {  
      //   background-color: #ffcccc; /* 第一个块的背景色 */  
      // } 
      .top_data_block1 {

        background: linear-gradient(135deg, #FFFFFF 0%, #D7F2F9 100%);
      }

      .top_data_block2 {
        background: linear-gradient(134deg, #D6F1E1 0%, #C7EFE4 100%);
      }

      .top_data_block3 {
        background: linear-gradient(134deg, #C2E8FF 0%, #7EBFFF 100%);
      }

      .top_data_block4 {
        background: linear-gradient(135deg, #FCF6D7 0%, #F8EABC 100%);
      }

      .top_data_block5 {
        background: linear-gradient(136deg, #B3CDFF 0%, #7F94FF 100%);
      }

      .top_data_block6 {
        // background: linear-gradient(134deg, #C9C9C9 0%, #767B7E 100%);
        background: linear-gradient( 134deg, #E3E7EE 0%, #D4D9E1 100%);
      }

      .top_data {
        height: 100%;
        // align-items: flex-end;
        flex: 1;

        .top_data_block {
          border-radius: 12px;
          width: 33%;
          height: 120px;
        }

        .top_img {
          width: 56px;
          height: 56px;
          margin-left: 30px;
          margin-right: 42px;
        }

        .top_main {}

        .top_main_title {
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB;
          font-weight: 400;
          font-size: 16px;
          color: #334A65;
          line-height: 19px;
          margin-bottom: 10px;
        }

        .top_main_num {
          font-family: FZLanTingHei-EB-GBK, FZLanTingHei-EB-GBK;
          font-weight: 400;
          font-size: 22px;
          color: #000000;
          line-height: 26px;
          letter-spacing: 1px;
        }

        .top_main_bottom {
          margin-top: 10px;

          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB;
          font-weight: 400;
          font-size: 16px;
          color: #334A65;
          line-height: 19px;
        }
      }

      .top_use {
        width: calc(34% - 12px);
        height: 100%;

        .chart-content {
          // 图表
          height: 240px;
        }
      }
    }

    .board_second {
      height: 300px;

      .board_income {
        height: 100%;
        margin-right: 24px;

        .chart-content-income {
          height: 260px;
        }
      }

      .board_occupied {
        width: calc(34% - 12px);
        height: 100%;

        .board_occupied_btm {}

        .board_occupied_main {
          width: 50%;
          padding: 15px 32px;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        .board_occupied_box {
          width: 40%;
          text-align: center;
          padding: 15px 0;
        }

        .board_occupied_title {
          font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB;
          font-weight: 400;
          font-size: 14px;
          color: #334A65;
          line-height: 16px;
          margin-bottom: 5px;
        }

        .board_occupied_num {
          font-family: FZLanTingHei-EB-GBK, FZLanTingHei-EB-GBK;
          font-weight: 600;
          font-size: 16px;
          color: #000000;
          line-height: 19px;
          letter-spacing: 1px;
        }

        .chart-content-occupied {
          width: 50%;
          height: 260px;
          position: relative;

          img {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 43%;
            left: 43%;
          }
        }
      }
    }

    .board_middle {
      height: 351px;

      .board_time {
        width: calc(33% - 12px);
        height: 100%;
        margin-right: 24px;

        .chart-content-time {
          height: 300px;
        }
      }

      .board_flow {
        height: 100%;
        width: calc(33% - 12px);
        margin-right: 24px;

        .chart-content-flow {
          height: 320px;
        }
      }

      .board_space {
        width: 33%;
        height: 100%;

        .chart-content_space {
          padding: 15px;
        }
      }
    }

    .board_bottom {
      height: 378px;

      .board_type {
        width: calc(33% - 12px);
        height: 100%;
        margin-right: 24px;

        .chart-content-type {
          height: 350px;
        }
      }

      .board_task {
        width: calc(33% - 12px);
        height: 100%;
        margin-right: 24px;

        .chart-content-task {
          height: 350px;
        }

        .board_task_select {
          margin-top: 10px;
          margin-right: 20px;
        }
      }

      .board_analysis {
        width: 33%;
        height: 100%;

        .board_analysis_main {}

        .board_analysis_box {
          padding: 20px 20px;
        }

        .board_analysis_block {
          width: 226px;
          height: 76px;
          background: #F0F3F7;
          border-radius: 4px;

          img {
            margin-left: 30px;
            width: 29px;
            height: 29px;
            margin-right: 20px;
          }
        }

        .board_analysis_title {
          font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB;
          font-weight: 400;
          font-size: 14px;
          color: #5F666C;
          line-height: 14px;
          margin-bottom: 5px;
        }

        .board_analysis_num {
          font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB;
          font-weight: 400;
          font-size: 22px;
          color: #000000;
          line-height: 22px;
        }

        .chart-content-analysis {
          height: 260px;
        }
      }
    }
  }
}



/deep/ .el-radio-button__orig-radio+.el-radio-button__inner:hover {
  color: #0768FD !important;
}

/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #fff;
  background-color: #0768FD !important;
  border-color: #0768FD !important;
  box-shadow: -1px 0 0 0 #0768FD !important;
}
/deep/ .el-radio-button__inner{
  background-color: #F3F3F3;
  border:none !important;
}

/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner:hover,
/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner:focus {
  color: #ffffff !important;
}

/deep/ .el-tooltip__popper.is-light {
  border: none !important;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05), 0px 3px 5px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

///deep/ .el-tooltip{
//  border: white 1px solid;
//}
// 修改三角的背景颜色 el-tooltip__popper is-light tooltip
///deep/ .el-tooltip__popper .popper__arrow::after {
//  border-top-color: #0A3C84;
//}
//// 修改三角的边框颜色
///deep/ .el-tooltip__popper .popper__arrow {
//  border-top-color: #4183C9;
//}</style>
<style lang="css">
/deep/ .el-tooltip__popper.is-light {
  border: none !important;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05), 0px 3px 5px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px -1px rgba(0, 0, 0, 0.1) !important;
}
</style>
